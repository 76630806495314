<template>
  <div class='p-5 bg-light'>
    <confirm-role-modal
      :modalStatus="confirmRoleModalStatus"
      :projectList="userProjectList"
      :username="username"
      @changeViewerRole="changeViewerRole"
      @cancelViewerRoleChange="cancelViewerRoleChange"
      @setAdminInSelect="setAdminInSelect"
    />
    <section class='w-75 m-auto pt-5' v-if='onlyAddCompanyUsers' > 
      <b-alert show variant="primary">
        <h4>Welcome to Orbital Stack!</h4>
        <p>
        Please take a minute to add new users to your Company so they can view, submit or manage projects.
        </p>
        <div class="d-flex flex-row justify-content-end" style="margin-top:0 !important;"> 
          <router-link :to="{ name: 'ProjectList' }" style="border: 1px #0173B9 solid; padding: 0.3rem;border-radius:3px;" class="text-decoration-none font-weight-bold"> Skip for now </router-link>
        </div>
      </b-alert>
    </section>
    <section v-if='!onlyAddCompanyUsers' class='w-75 m-auto'>
      <h6>Subscriptions</h6>
      <div class='d-flex'>
        <div class='mr-4 bg-white border rounded'>
          <h5 class='p-4 border-bottom'>Monthly</h5>
          <div class='pl-4 text-uppercase text-primary font-weight-bold'>active plans</div>
          <div class='pl-4 pr-4'>
            <div class='font-weight-bold'>Basic</div>
            <b-table-simple class='border' small style='font-size: 0.875rem;'>
              <b-thead style='background-color: #E9ECEF;'>
                <b-tr>
                  <b-th class='pl-4'>Label</b-th>
                  <b-th>Usage</b-th>
                  <b-th>Start date</b-th>
                  <b-th>Expiry date</b-th>
                  <b-th>Monthly roll over day</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class='bg-white mh-50 overflow-auto' v-if='companyBasicSubscriptions.length > 0'>
                <b-tr v-for='companySubscription in companyBasicSubscriptions' :key='companySubscription.id'>
                  <b-th class='pl-4 font-weight-light align-middle'>{{ companySubscription.label }}</b-th>
                  <b-th class='font-weight-light align-middle'>
                    {{ companySubscription.credits_used_this_month }}/{{ companySubscription.subscription_type.monthly_wind_directions }}
                  </b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.start_date }}</b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.expiry_date }}</b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.monthly_roll_over_day }}</b-th>
                </b-tr>
              </b-tbody>
              <b-tbody class='bg-white' v-else-if='!companySubscriptionsLoading'>
                <b-tr>
                  <b-th
                    colspan='5'
                    class='font-weight-light text-center'
                    style='color: #9CA3AF;'>
                    No active plans
                  </b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <loading-state v-if='companySubscriptionsLoading && companySubscriptions.length === 0' class='d-flex justify-content-center'/>
            <div class='font-weight-bold'>Standard</div>
            <b-table-simple class='border' small style='font-size: 0.875rem;'>
              <b-thead style='background-color: #E9ECEF;'>
                <b-tr>
                  <b-th class='pl-4'>Label</b-th>
                  <b-th>Usage</b-th>
                  <b-th>Start date</b-th>
                  <b-th>Expiry date</b-th>
                  <b-th>Monthly roll over day</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class='bg-white mh-50 overflow-auto' v-if='companyStandardSubscriptions.length > 0'>
                <b-tr v-for='companySubscription in companyStandardSubscriptions' :key='companySubscription.id'>
                  <b-th class='pl-4 font-weight-light align-middle'>{{ companySubscription.label }}</b-th>
                  <b-th class='font-weight-light align-middle'>
                    {{ companySubscription.credits_used_this_month }}/{{ companySubscription.subscription_type.monthly_wind_directions }}
                  </b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.start_date }}</b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.expiry_date }}</b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.monthly_roll_over_day }}</b-th>
                </b-tr>
              </b-tbody>
              <b-tbody class='bg-white' v-else-if='!companySubscriptionsLoading'>
                <b-tr>
                  <b-th
                    colspan='5'
                    class='font-weight-light text-center'
                    style='color: #9CA3AF;'>
                    No active plans
                  </b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <loading-state v-if='companySubscriptionsLoading && companySubscriptions.length === 0' class='d-flex justify-content-center'/>
            <div class='font-weight-bold'>Premium</div>
            <b-table-simple class='border' small style='font-size: 0.875rem;'>
              <b-thead style='background-color: #E9ECEF;'>
                <b-tr>
                  <b-th class='pl-4'>Label</b-th>
                  <b-th>Usage</b-th>
                  <b-th>Start date</b-th>
                  <b-th>Expiry date</b-th>
                  <b-th>Monthly roll over day</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class='bg-white mh-50 overflow-auto' v-if='companyPremiumSubscriptions.length > 0'>
                <b-tr v-for='companySubscription in companyPremiumSubscriptions' :key='companySubscription.id'>
                  <b-th class='pl-4 font-weight-light align-middle'>{{ companySubscription.label }}</b-th>
                  <b-th class='font-weight-light align-middle'>
                    {{ companySubscription.credits_used_this_month }}/{{ companySubscription.subscription_type.monthly_wind_directions }}
                  </b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.start_date }}</b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.expiry_date }}</b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscriptions.monthly_roll_over_day }}</b-th>
                </b-tr>
              </b-tbody>
              <b-tbody class='bg-white' v-else-if='!companySubscriptionsLoading'>
                <b-tr>
                  <b-th
                    colspan='5'
                    class='font-weight-light text-center'
                    style='color: #9CA3AF;'>
                    No active plans
                  </b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <loading-state v-if='companySubscriptionsLoading && companySubscriptions.length === 0' class='d-flex justify-content-center'/>
          </div>
        </div>
        <div class='mt-0 ml-4 bg-white border rounded'>
          <h5 class='p-4 border-bottom'>Pay-per-use</h5>
          <div class='pl-4 text-uppercase text-primary font-weight-bold'>credits available</div>
          <div class='pl-4 pr-4'>
            <div class='font-weight-bold'>AI</div>
            <b-table-simple class='border' small style='font-size: 0.875rem;'>
              <b-thead style='background-color: #E9ECEF;'>
                <b-tr>
                  <b-th class='pl-4'>Label</b-th>
                  <b-th>Credits used</b-th>
                  <b-th>Start date</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class='bg-white mh-50 overflow-auto' v-if='companyAISubscriptions.length > 0'>
                <b-tr v-for='companySubscription in companyAISubscriptions' :key='companySubscription.id'>
                  <b-th class='font-weight-light pl-4 align-middle'>{{ companySubscription.label }}</b-th>
                  <b-th class='font-weight-light align-middle'>
                    {{ companySubscription.total_credits_used }}/{{ companySubscription.pay_per_use_credits }}
                  </b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.start_date }}</b-th>
                </b-tr>
              </b-tbody>
              <b-tbody class='bg-white' v-else-if='!companySubscriptionsLoading'>
                <b-tr>
                  <b-th
                    colspan='3'
                    class='font-weight-light text-center'
                    style='color: #9CA3AF;'>
                    No credits available
                  </b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <loading-state v-if='companySubscriptionsLoading && companySubscriptions.length === 0' class='d-flex justify-content-center'/>
          </div>
          <div class='pl-4 pr-4'>
            <div class='font-weight-bold'>CFD</div>
            <b-table-simple class='border' small style='font-size: 0.875rem;'>
              <b-thead style='background-color: #E9ECEF;'>
                <b-tr>
                  <b-th class='pl-4'>Label</b-th>
                  <b-th>Credits used</b-th>
                  <b-th>Start date</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class='bg-white mh-50 overflow-auto' v-if='companyCFDSubscriptions.length > 0'>
                <b-tr v-for='companySubscription in companyCFDSubscriptions' :key='companySubscription.id'>
                  <b-th class='font-weight-light pl-4 align-middle'>{{ companySubscription.label }}</b-th>
                  <b-th class='font-weight-light align-middle'>
                    {{ companySubscription.total_credits_used }}/{{ companySubscription.pay_per_use_credits }}
                  </b-th>
                  <b-th class='font-weight-light align-middle'>{{ companySubscription.start_date }}</b-th>
                </b-tr>
              </b-tbody>
              <b-tbody class='bg-white' v-else-if='!companySubscriptionsLoading'>
                <b-tr>
                  <b-th
                    colspan='3'
                    class='font-weight-light text-center'
                    style='color: #9CA3AF;'>
                    No credits available
                  </b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <loading-state v-if='companySubscriptionsLoading && companySubscriptions.length === 0' class='d-flex justify-content-center'/>
          </div>
          <div class='pl-4 text-uppercase text-primary font-weight-bold'>pricing</div>
          <div class='pl-4 pr-4'>
            <b-table-simple small class='border' style='font-size: 0.875rem;'>
              <b-thead style='background-color: #E9ECEF;'>
                <b-tr>
                  <b-th class='pl-4'>Credit type</b-th>
                  <b-th>Price</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class='bg-white mh-50 overflow-auto'>
                <b-tr v-for='creditCost in companyCreditCost' :key='creditCost.id'>
                  <b-th class='font-weight-light pl-4 align-middle'>{{ creditCost.subscription_type.credit_type }}</b-th>
                  <b-th class='font-weight-light align-middle'>${{ creditCost.price }}</b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <loading-state v-if='companySubscriptionsLoading && companySubscriptions.length === 0' class='d-flex justify-content-center'/>
          </div>
        </div>
      </div>
      <div class='bg-white border rounded mt-5'>
        <h5 class='p-4 border-bottom'>Usage history</h5>
        <div class='p-4 mt-1'>
          <b-table class='border' small style='font-size: 0.875rem;'
            show-empty
            sort-icon-left
            thead-class="admin-tables-header"
            tbody-tr-class="font-weight-light"
            :items="companySubscriptionUsage"
            :fields="companySubscriptionUsageFields"
            :no-local-sorting="true"
            :no-sort-reset="true"
            :sort-by="companySubscriptionUsageSortBy"
            :sort-desc="companySubscriptionUsageSortDesc"
            :busy="companySubscriptionUsageLoading"
            @sort-changed="companySubscriptionUsageSortChanged">
            <template #empty>
                <div
                  class='font-weight-light text-center'
                  style='color: #9CA3AF;'>
                  No usage yet
                </div>
            </template>
            <template #table-busy>
              <loading-state class='d-flex justify-content-center'/>
            </template>
          </b-table>
          <b-pagination
            v-model='subscriptionUsagePage'
            :total-rows='companySubscriptionUsageCount'
            :per-page='subscriptionUsagePageSize'
            @input='subscriptionUsagePageChange'
            pills
            size='sm'
            align='right'>
          </b-pagination>
        </div>
      </div>
    </section>
    <section class='w-75 m-auto' v-if='canViewUsers'>
      <div class='bg-white border rounded mt-5'>
        <div class="p-4 border-bottom">
          <h6 class='d-inline'>User Management</h6>
          <b-button
            v-if='!showAddUserForm'
            size='sm'
            variant='primary'
            class='float-right m-0'
            @click='toggleAddUserForm'>
            Add user
          </b-button>
        </div>
        <div class='bg-white p-4 mt-0 border-bottom rounded' v-if='showAddUserForm'>
          <FormulateForm @submit='addCompanyUser'>
            <div class='d-flex flex-row justify-content-between'>
              <FormulateInput
                v-model='newEmail'
                type='email'
                class='m-0'
                placeholder='Email'
                validation='required|email' />
              <FormulateInput
                v-model='newFirstName'
                type='text'
                class='m-0 ml-2'
                name='First name'
                placeholder='First name'
                validation='required'/>
              <FormulateInput
                v-model='newLastName'
                type='text'
                class='m-0 ml-2'
                name='Last name'
                placeholder='Last name'
                validation='required' />
              <b-form-select
                class='w-auto ml-2'
                v-model='newRole'
                size='sm'>
                <template #first>
                  <b-form-select-option :value='null' disabled>Role</b-form-select-option>
                </template>
                <b-form-select-option
                  v-for='companyRole in companyRoles'
                  :value='companyRole.name'
                  :key='companyRole.name'>
                  {{ toBasicRoleName(companyRole.name) }}
                </b-form-select-option>
              </b-form-select>
              <FormulateInput
                v-model='newPassword'
                type='password'
                class='m-0 ml-2'
                placeholder='Password'
                validation='required|min:8,length|matches:/[0-9]/|matches:/[a-z]/|matches:/[_!@#$%^&*]/'
                :validation-messages="{ matches: 'Password must contain at least one number, one letter, and one symbol' }" />
            </div>
            <div class='d-flex flex-row-reverse'>
              <b-button
                type='submit'
                variant='primary'
                class='m-0 ml-3'
                :disabled='!canAddCompanyUser || creatingUser'>
                <b-spinner v-if='creatingUser' small></b-spinner>
                Add user
              </b-button>
              <b-button
                variant='outline-secondary'
                @click='toggleAddUserForm'>
                Cancel
              </b-button>
            </div>
          </FormulateForm>
        </div>
        <div class="p-4 mt-1">
          <b-table-simple small class='border' style='font-size: 0.875rem;'>
            <b-thead style='background-color: #E9ECEF;'>
              <b-tr>
                <b-th class='pl-4' colspan='2'>Email/Username</b-th>
                <b-th>First Name</b-th>
                <b-th>Last Name</b-th>
                <b-th v-if='!onlyAddCompanyUsers'>Subscription</b-th>
                <b-th>Role</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody class='bg-white mh-50 overflow-auto'>
              <b-tr v-for='companyUser in companyUsers' :key='companyUser.id'>
                <b-th class='font-weight-light pl-4 align-middle text-break' colspan='2'>{{ companyUser.email }}</b-th>
                <b-th class='font-weight-light align-middle text-break'>{{ companyUser.first_name }}</b-th>
                <b-th class='font-weight-light align-middle text-break'>{{ companyUser.last_name }}</b-th>
                <b-th  v-if='!onlyAddCompanyUsers'>
                  <b-form-select
                    size='sm'
                    class='w-50 h-auto'
                    :value='companyUser.subscription'
                    @change='updateCompanyUserSubscription(companyUser.id, $event)'
                    :disabled='isUserRoleViewer(companyUser.role)'>
                    <template #first>
                      <b-form-select-option
                        :value='null'>
                        None
                      </b-form-select-option>
                    </template>
                    <template v-if="!isUserRoleViewer(companyUser.role)">
                      <b-form-select-option
                        v-for='subscription in companyMonthlySubscriptions'
                        :key='subscription.id'
                        :value='subscription.id'>
                        {{ subscription.label }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-th>
                <b-th>
                  <b-form-select
                    size='sm'
                    :id='companyUser.id.toString()'
                    class='w-50 h-auto'
                    :value="companyUser.role"
                    @change='updateCompanyUserRole(companyUser, $event)'
                    :disabled='companyUser.id === loggedInUser.id'>
                    <template #first>
                      <b-form-select-option :value='null' disabled>None</b-form-select-option>
                    </template>
                    <b-form-select-option
                      v-for='companyRole in companyRoles'
                      :value='companyRole.name'
                      :key='companyRole.name'
                      >
                      {{ toBasicRoleName(companyRole.name) }}
                    </b-form-select-option>
                  </b-form-select>
                </b-th>
                <b-th class='text-right pr-4 align-middle'>
                  <div
                    v-if='companyUser.id === recentlyUpdatedCompanyUserId'
                    class='font-weight-light border border-success rounded text-success text-center small'>
                    <b-icon icon='check'></b-icon>
                    Changes saved
                  </div>
                  <div
                    v-else-if='companyUser.id === failureToUpdateCompanyUserId'
                    class='font-weight-light border border-danger rounded text-danger text-center small'>
                    <b-icon icon='x'></b-icon>
                    Failed to update
                  </div>
                  <b-spinner
                    v-else-if='companyUser.id === deletingUserId || companyUser.id === updatingUserId'
                    small>
                  </b-spinner>
                  <b-icon
                    v-else-if='companyUser.id !== loggedInUser.id'
                    icon='trash'
                    role='button'
                    @click='openDeleteUserModal(companyUser)'>
                  </b-icon>
                  <b-icon v-else icon='dash'></b-icon>
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <loading-state v-if='companyUsersLoading && companyUsers.length === 0' class='d-flex justify-content-center'/>
          <b-pagination
            v-model='companyUsersPage'
            :total-rows='companyUsersCount'
            :per-page='companyUsersPageSize'
            pills
            size='sm'
            align='right'>
          </b-pagination>
        </div>
      </div>
    </section>
    <b-modal centered hide-header-close title="Unassign subscriptions?" v-model='showViewerModal'>
      <p>Changing the role to <strong>Viewer</strong> will unassign any subscriptions. Do you want to continue?</p>
      <template #modal-footer>
        <div class="d-flex flex-row justify-content-between">
        <b-button @click='closeViewerModal'>No</b-button>
        <b-button @click='changeViewerRole' variant='danger' class='float-right'>Yes</b-button>
        </div>
      </template>
    </b-modal>
    <b-modal centered hide-header-close title="Are you sure?" v-if="companyUserToDelete" v-model='showDeleteUserModal'>
      <p>Are you sure you wish to delete {{ companyUserToDelete.email}}?</p>
      <template #modal-footer>
        <b-button @click='closeDeleteUserModal' class='float-left'>Cancel</b-button>
        <b-button @click='deleteCompanyUser' variant='danger' class='float-right'>Delete</b-button>
      </template>
    </b-modal>
    <section class='w-75 m-auto pt-5' v-if='canViewShareGroups && !onlyAddCompanyUsers'>
      <div v-if='showCompanyShareGroupUsers'>
        <b-button
          variant='link'
          @click='goToCompanyShareGroups'
          class='bg-light text-decoration-none'>
          <b-icon icon='arrow-left-short'></b-icon>
          Share groups
        </b-button>
      </div>
      <div class='bg-white border rounded'>
        <div class='p-4 border-bottom d-flex flex-row justify-content-between'>
          <h6 v-if='!showCompanyShareGroupUsers'>Share Group Management</h6>
          <h6 v-else>Share Group User Management</h6>
          <b-button
            v-if='!showAddShareGroupForm && !showCompanyShareGroupUsers'
            size='sm'
            variant='primary'
            class='m-0'
            @click='toggleAddShareGroupForm'>
            Create share group
          </b-button>
        </div>
        <div
          class='p-4 border-bottom mt-0 d-flex flex-row justify-content-between'
          v-if='showCompanyShareGroupUsers'>
          <b-form-select
            size='sm'
            class='w-auto h-auto m-0'
            :value='selectedCompanyShareGroup'
            @change='goToCompanyShareGroupUsers($event)'>
            <b-form-select-option
              v-for='companyShareGroup in companyShareGroups'
              :value='companyShareGroup'
              :key='companyShareGroup.id'>
              {{ companyShareGroup.group_name }}
            </b-form-select-option>
          </b-form-select>
          <b-button
            size='sm'
            variant='primary'
            class='m-0'
            v-if='!selectedCompanyShareGroup.all_company_share'
            :disabled='companyShareGroupUsersLoading'
            @click='openAddCompanyUsersToShareGroupModal'>
            <b-icon icon='plus'></b-icon>
            Add users to this group
          </b-button>
        </div>
        <div class='bg-white p-4 border-bottom rounded mt-0' v-if='showAddShareGroupForm && !showCompanyShareGroupUsers'>
          <div class='d-flex flex-row justify-content-between'>
            <FormulateInput
              v-model='newGroupName'
              type='text'
              class='m-0'
              placeholder='Group name'
              validation='required' />
            <FormulateInput
              v-model='newDefaultShareGroup'
              type='checkbox'
              class='m-0 ml-2'
              name='Default share group'
              label='Set share group as default for new projects'
              label-class='m-0'
              decorator-class='formulate-input-element-decorator m-2' />
            <div class='d-flex m-0'>
              <b-button
                variant='outline-secondary'
                @click='toggleAddShareGroupForm'>
                Cancel
              </b-button>
              <b-button
                variant='primary'
                class='m-0 ml-3'
                :disabled='!canAddShareGroup || creatingShareGroup'
                @click='addShareGroup'>
                <b-spinner small v-if='creatingShareGroup'></b-spinner>
                Add share group
              </b-button>
            </div>
          </div>
        </div>
        <div class="p-4 mt-1" v-if='!showCompanyShareGroupUsers'>
          <b-table-simple small class='border' style='font-size: 0.875rem;'>
            <b-thead style='background-color: #E9ECEF;'>
              <b-tr>
                <b-th class='pl-4'>Group Name</b-th>
                <b-th class='text-center'>All Company Share</b-th>
                <b-th class='text-center'>Default for New Projects</b-th>
                <b-th colspan='2'></b-th>
              </b-tr>
            </b-thead>
            <b-tbody class='bg-white mh-50 overflow-auto'>
              <b-tr v-for='companyShareGroup in companyShareGroups' :key='companyShareGroup.id'>
                <b-th class='font-weight-light pl-4 align-middle text-break'>
                  {{ companyShareGroup.group_name }}
                </b-th>
                <b-th class='font-weight-light align-middle text-center'>
                  <b-icon v-if='companyShareGroup.all_company_share' icon='check'></b-icon>
                  <b-icon v-else icon='dash'></b-icon>
                </b-th>
                <b-th class='font-weight-light align-middle text-center'>
                  <b-icon v-if='companyShareGroup.default_for_new_projects' icon='check'></b-icon>
                  <b-icon v-else icon='dash'></b-icon>
                </b-th>
                <b-th class='text-right pr-4' colspan='2'>
                  <b-button
                    variant='link'
                    size='sm'
                    class='text-dark bg-white mr-2'
                    :disabled='companyShareGroup.all_company_share'
                    @click='goToCompanyShareGroupUsers(companyShareGroup)'>
                    <b-icon icon='people-fill'></b-icon>
                    Manage users
                  </b-button>
                  <b-button
                    variant='link'
                    size='sm'
                    class='text-dark bg-white mr-2'
                    :disabled='companyShareGroup.default_for_new_projects || deletingShareGroupId === companyShareGroup.id'
                    @click='openUpdateShareGroupModal(companyShareGroup)'>
                    <b-icon icon='pencil-square'></b-icon>
                    Edit
                  </b-button>
                  <b-button
                    variant='link'
                    size='sm'
                    class='text-dark bg-white'
                    :disabled='companyShareGroup.all_company_share || companyShareGroup.default_for_new_projects || deletingShareGroupId === companyShareGroup.id'
                    @click='openDeleteShareGroupModal(companyShareGroup)'>
                    <b-spinner small v-if='deletingShareGroupId === companyShareGroup.id'></b-spinner>
                    <b-icon v-else icon='trash'></b-icon>
                    Delete
                  </b-button>
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <loading-state v-if='companyShareGroupsLoading && companyShareGroups.length === 0' class='d-flex justify-content-center'/>
        </div>
        <div class="p-4 mt-1" v-if='showCompanyShareGroupUsers'>
          <b-table-simple class='border' small style='font-size: 0.875rem;'>
            <b-thead style='background-color: #E9ECEF;'>
              <b-tr>
                <b-th class='pl-4' colspan='2'>Email/Username</b-th>
                <b-th>First Name</b-th>
                <b-th>Last Name</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody class='bg-white mh-50 overflow-auto' v-if='companyShareGroupUsers.length > 0'>
              <b-tr v-for='companyShareGroupUser in companyShareGroupUsers' :key='companyShareGroupUser.id'>
                <b-th class='font-weight-light pl-4 align-middle text-break' colspan='2'>{{ companyShareGroupUser.email }}</b-th>
                <b-th class='font-weight-light align-middle text-break'>{{ companyShareGroupUser.first_name }}</b-th>
                <b-th class='font-weight-light align-middle text-break'>{{ companyShareGroupUser.last_name }}</b-th>
                <b-th class='font-weight-light align-middle text-right pr-4'>
                  <b-icon
                    icon='dash'
                    v-if='selectedCompanyShareGroup.all_company_share'>
                  </b-icon>
                  <b-spinner small v-else-if='companyShareGroupUser.id === deletingShareGroupUserId'></b-spinner>
                  <b-icon
                    v-else
                    icon='trash'
                    role='button'
                    @click='removeCompanyShareGroupUser(companyShareGroupUser.id)'>
                  </b-icon>
                </b-th>
              </b-tr>
            </b-tbody>
            <b-tbody class='bg-white' v-else-if='!companyShareGroupUsersLoading'>
              <b-tr>
                <b-th
                  colspan='5'
                  class='font-weight-light text-center'
                  style='color: #9CA3AF;'>
                  No users to show in this share group
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <loading-state
            v-if='companyShareGroupUsersLoading && showCompanyShareGroupUsers && companyShareGroupUsers.length === 0'
            class='d-flex justify-content-center'/>
        </div>
      </div>
    </section>
    <b-modal centered hide-header-close title="Are you sure?" v-if="companyShareGroupToDelete" v-model='showDeleteShareGroupModal'>
      <p>Are you sure you wish to delete the share group {{ companyShareGroupToDelete.group_name}}?</p>
      <template #modal-footer>
        <b-button @click='closeDeleteShareGroupModal' class='float-left'>Cancel</b-button>
        <b-button @click='deleteCompanyShareGroup' variant='danger' class='float-right'>Delete</b-button>
      </template>
    </b-modal>
    <b-modal centered hide-header-close :title="companyShareGroupToUpdate.group_name" v-if="companyShareGroupToUpdate" v-model='showUpdateShareGroupModal'>
      <FormulateInput
          v-model='setCompanyShareGroupToDefault'
          type='checkbox'
          decorator-class='formulate-input-element-decorator m-0'
          label-class='formulate-input-label formulate-input-label--after mt-0'
          label='Set share group as default for new projects'/>
      <template #modal-footer>
        <b-button class="float-left" @click='closeUpdateShareGroupModal'>Close</b-button>
        <b-button
          @click='updateCompanyShareGroup(companyShareGroupToUpdate.id)'
          :disabled='!setCompanyShareGroupToDefault || companyShareGroupToUpdate.id === updatingShareGroupId'
          variant='primary'
          class='ml-4 float-right'>
          <b-spinner small v-if='companyShareGroupToUpdate.id === updatingShareGroupId'></b-spinner>
          Save changes
        </b-button>
      </template>
    </b-modal>
    <b-modal centered hide-header-close v-model='showAddCompanyUsersToShareGroupModal'>
      <template #modal-header>
        <p>Add users to the share group: <strong>{{ selectedCompanyShareGroup.group_name }}</strong></p>
      </template>
      <template #default>
        <FormulateInput
          v-model='companyUserToAddToShareGroupFilter'
          class='w-25'
          type='search'
          placeholder='Search users' />
        <b-table-simple class='border' small style='font-size: 0.875rem;'>
          <b-thead style='background-color: #E9ECEF;'>
            <b-tr>
              <b-th class='pl-4' colspan='2'>Email</b-th>
              <b-th>First Name</b-th>
              <b-th>Last Name</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody class='bg-white mh-50 overflow-auto' v-if='companyUsersToAddToShareGroup.length > 0'>
            <b-tr
              v-for='companyUser in companyUsersToAddToShareGroup'
              :key='companyUser.id'
              @click='updateSelectedCompanyUsersToAddToShareGroup(companyUser)'>
              <b-th class='font-weight-light pl-4 align-middle text-break' colspan='2'>{{ companyUser.email }}</b-th>
              <b-th class='font-weight-light align-middle text-break'>{{ companyUser.first_name }}</b-th>
              <b-th class='font-weight-light align-middle text-break'>{{ companyUser.last_name }}</b-th>
              <b-th class='font-weight-light align-middle'>
                <FormulateInput
                  disabled
                  type='checkbox'
                  v-model='companyUser.selected'
                  decorator-class='formulate-input-element-decorator m-0'
                  outer-class='formulate-input float-right pr-4'/>
              </b-th>
            </b-tr>
          </b-tbody>
          <b-tbody class='bg-white' v-else>
            <b-tr>
              <b-th
                colspan='5'
                class='font-weight-light text-center'
                style='color: #9CA3AF'>
                All users already in share group
              </b-th>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-pagination
          v-model='addCompanyUsersPage'
          :total-rows='addCompanyUsersCount'
          :per-page='addCompanyUsersPageSize'
          pills
          size='sm'
          align='right'>
        </b-pagination>
      </template>
      <template #modal-footer>
        <div class='d-flex flex-row-reverse'>
          <b-button
            variant='primary'
            class='ml-3'
            @click='addSelectedCompanyUsersToShareGroup'
            :disabled='selectedCompanyUsersToAddToShareGroup.length === 0 || addingShareGroupUsers'>
            <b-spinner small v-if='addingShareGroupUsers'></b-spinner>
            Add selected users
          </b-button>
          <b-button
            @click='closeAddCompanyUsersToShareGroupModal'
            variant='outline-secondary'>
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  GET_PERMISSIONS,
  GET_COMPANY_USERS,
  GET_COMPANY_USERS_TO_ADD,
  CREATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  UPDATE_COMPANY_USER,
  GET_COMPANY_ROLES,
  GET_COMPANY_SHARE_GROUPS,
  CREATE_COMPANY_SHARE_GROUP,
  DELETE_COMPANY_SHARE_GROUP,
  UPDATE_COMPANY_SHARE_GROUP,
  GET_COMPANY_SHARE_GROUP_USERS,
  ADD_COMPANY_SHARE_GROUP_USERS,
  REMOVE_COMPANY_SHARE_GROUP_USER,
  GET_COMPANY_SUBSCRIPTIONS,
  GET_COMPANY_SUBSCRIPTION_USAGE,
  GET_COMPANY_SUBSCRIPTION_TYPE_PRICES
} from '@/store/actions/permissions';
import { CHANGE_USER, CHANGE_SHARE_GROUP } from '@/constants/permissions';
import LoadingState from '@/components/views/LoadingState';
import { EventBus } from '@/network/eventbus';
import { orderByKey } from '@/utilities/order-by';
import ConfirmRoleModal from './ConfirmRoleModal.vue';

export default {
  name: 'CompanyAdministration',
  components: {
    LoadingState,
    ConfirmRoleModal
  },
  data() {
    return {
      showAdminInSelect: false,
      username: '',
      projectAdminId: null,
      userProjectList: [],
      newEmail: '',
      newFirstName: '',
      newLastName: '',
      newPassword: '',
      confirmRoleModalStatus: 0,
      newRole: null,
      showAddUserForm: false,
      creatingUser: false,
      showDeleteUserModal: false,
      deletingUserId: null,
      companyUserToDelete: null,
      updatingUserId: null,
      showAddShareGroupForm: false,
      newGroupName: '',
      newDefaultShareGroup: false,
      creatingShareGroup: false,
      showDeleteShareGroupModal: false,
      companyShareGroupToDelete: null,
      deletingShareGroupId: null,
      showUpdateShareGroupModal: false,
      companyShareGroupToUpdate: null,
      updatingShareGroupId: null,
      showCompanyShareGroupUsers: false,
      selectedCompanyShareGroup: null,
      showAddCompanyUsersToShareGroupModal: false,
      selectedCompanyUsersToAddToShareGroup: [],
      addingShareGroupUsers: false,
      companyUserToAddToShareGroupFilter: '',
      deletingShareGroupUserId: null,
      companyUsersLoading: false,
      companyUsersPage: 1,
      companyUsersPageSize: 10,
      companyShareGroupsLoading: false,
      companyShareGroupUsersLoading: false,
      setCompanyShareGroupToDefault: false,
      companySubscriptionsLoading: false,
      tbodyclass: 'bg-white mh-50 overflow-auto',
      theadclass: 'background-color: #E9ECEF;',
      companySubscriptionUsageFields: [
        {
          key: 'subscription.label',
          label: 'Label',
          sortable: true,
        },
        {
          key: 'user',
          label: 'Used by',
          sortable: true,
          formatter: user => {
            return user ? user.username : null;
          }
        },
        {
          key: 'subscription.subscription_type.credit_type',
          label: 'Credit type',
          sortable: true
        },
        {
          key: 'used_date',
          label: 'Date',
          sortable: true
        },
        {
          key: 'number_credits_used',
          label: 'Credits used',
          sortable: true
        },
        {
          key: 'configuration_name',
          label: 'Configuration',
          sortable: true
        },
        {
          key: 'project_name',
          label: 'Project',
          sortable: true
        }
      ],
      companySubscriptionUsageSortBy: 'used_date',
      companySubscriptionUsageSortDesc: true,
      companySubscriptionUsageLoading: false,
      subscriptionUsagePage: null,
      subscriptionUsagePageSize: 10,
      showViewerModal: false,
      updatingUserRole: null,
      previousUserRole: null,
      previousUserSubscription: null,
      addCompanyUsersPage: 1,
      addCompanyUsersPageSize: 10
    };
  },
  computed: {
    onlyAddCompanyUsers() {
      return this.$route.query.addusers === true;
    },
    canAddCompanyUser() {
      return this.newEmail &&
        this.newFirstName &&
        this.newLastName &&
        this.newPassword &&
        this.newRole;
    },
    updateUserRoleSelection() {
      if (this.previousUserRole) {
        return this.previousUserRole;
      } else {
        return this.updatingUserRole;
      }
    },
    canAddShareGroup() {
      return !!this.newGroupName;
    },
    addCompanyUserPayload() {
      return {
        email: this.newEmail,
        first_name: this.newFirstName,
        last_name: this.newLastName,
        password: this.newPassword,
        role: this.newRole,
        source: 'Customer staff'
      };
    },
    addCompanyShareGroupPayload() {
      return {
        group_name: this.newGroupName,
        default_for_new_projects: this.newDefaultShareGroup
      };
    },
    companyUsersToAddToShareGroup() {
      let companyShareGroupUserIds = this.companyShareGroupUsers.map(user => user.id);
      let companyUsersNotInShareGroup = this.addCompanyUsers.filter(user => !companyShareGroupUserIds.includes(user.id));
      if(this.companyUserToAddToShareGroupFilter === '') {
        return companyUsersNotInShareGroup;
      } else {
        let filter = this.companyUserToAddToShareGroupFilter.toLowerCase();
        return companyUsersNotInShareGroup.filter(user => {
          let full_name = `${user.first_name} ${user.last_name}`.toLowerCase();
          return user.email.includes(filter) || full_name.includes(filter);
        });
      }
    },
    canViewUsers() {
      return this.$store.getters['allowedPermissions'][CHANGE_USER];
    },
    canViewShareGroups() {
      return this.$store.getters['allowedPermissions'][CHANGE_SHARE_GROUP];
    },
    companyMonthlySubscriptions() {
      return this.companySubscriptions.filter(sub => sub.subscription_type.type === 'MONTHLY');
    },
    companyBasicSubscriptions() {
      return this.companySubscriptions.filter(sub => sub.subscription_type.display_tier === 'Basic');
    },
    companyStandardSubscriptions() {
      return this.companySubscriptions.filter(sub => sub.subscription_type.display_tier === 'Standard');
    },
    companyPremiumSubscriptions() {
      return this.companySubscriptions.filter(sub => sub.subscription_type.display_tier === 'Premium');
    },
    companyAISubscriptions() {
      return this.companySubscriptions.filter(sub =>
        sub.subscription_type.type === 'ONE-TIME'
        && sub.subscription_type.credit_type === 'AI'
        && sub.total_credits_used < sub.pay_per_use_credits);
    },
    companyCFDSubscriptions() {
      return this.companySubscriptions.filter(sub =>
        sub.subscription_type.credit_type === 'CFD'
        && sub.total_credits_used < sub.pay_per_use_credits);
    },
    companyCreditCost() {
      return this.companySubscriptionTypePrices.filter(price => price.subscription_type.tier === 'Pay-per-use');
    },
    ...mapGetters([
      'companyUsers',
      'addCompanyUsers',
      'companyUsersCount',
      'addCompanyUsersCount',
      'companyUserCreated',
      'companyUserDeleted',
      'createCompanyUserError',
      'deleteCompanyUserError',
      'recentlyUpdatedCompanyUserId',
      'failureToUpdateCompanyUserId',
      'companyRoles',
      'loggedInUser',
      'companyShareGroups',
      'companyShareGroupCreated',
      'createCompanyShareGroupError',
      'companyShareGroupDeleted',
      'deleteCompanyShareGroupError',
      'companyShareGroupUpdated',
      'updateCompanyShareGroupError',
      'companyShareGroupUsers',
      'companyShareGroupUsersAdded',
      'addCompanyShareGroupUsersError',
      'companyShareGroupUsersRemoved',
      'removeCompanyShareGroupUserError',
      'companySubscriptions',
      'companySubscriptionUsage',
      'companySubscriptionUsageCount',
      'companySubscriptionTypePrices'
    ])
  },
  async created() {
    this.companyUsersLoading = true;
    this.companyShareGroupsLoading = true;
    this.companySubscriptionsLoading = true;
    this.companySubscriptionUsageLoading = true;

    await this.$store.dispatch(GET_PERMISSIONS, [CHANGE_USER, CHANGE_SHARE_GROUP]);
    let canViewPage = this.canViewUsers || this.canViewShareGroups;
    if(!canViewPage) this.$router.push({ name: 'ProjectList' });
    await this.$store.dispatch(GET_COMPANY_SUBSCRIPTIONS);
    this.companySubscriptionsLoading = false;
    this.subscriptionUsagePage = 1;
    await Promise.all([
      this.$store.dispatch(GET_COMPANY_SUBSCRIPTION_TYPE_PRICES),
      this.$store.dispatch(GET_COMPANY_USERS, this.companyUsersPage),
      this.$store.dispatch(GET_COMPANY_USERS_TO_ADD, this.addCompanyUsersPage)
    ]);
    this.companyUsersLoading = false;
    await Promise.all([
      this.$store.dispatch(GET_COMPANY_ROLES),
      this.$store.dispatch(GET_COMPANY_SHARE_GROUPS)
    ]);
    this.companyShareGroupsLoading = false;
  },
  methods: {
    setAdminInSelect() {
      this.showAdminInSelect = true;
    },
    async setUserAdminProjectsNames(userId){
      return await this.$store.dispatch('project/getAdminProjectList', { 
        user_id: Number(userId)
      });
    },
    async removeUserAsProjectAdmin(userId){
      return await this.$store.dispatch('project/removeUserAsProjectAdmin', { 
        user_id: Number(userId)
      });
    },
    isUserRoleViewer(role) {
      if (role) {
        return role.slice(6) === 'viewer';
      } else {
        return false;
      }
    },
    toggleAddUserForm() {
      this.showAddUserForm = !this.showAddUserForm;
    },
    toggleAddShareGroupForm() {
      this.showAddShareGroupForm = !this.showAddShareGroupForm;
    },
    clearAddCompanyUserForm() {
      this.newEmail = '';
      this.newFirstName = '';
      this.newLastName = '';
      this.newPassword = '';
      this.newRole = null;
    },
    clearAddCompanyShareGroupForm() {
      this.newGroupName = '';
      this.newDefaultShareGroup = false;
    },
    async addCompanyUser() {
      this.creatingUser = true;
      await this.$store.dispatch(CREATE_COMPANY_USER, this.addCompanyUserPayload);
      this.creatingUser = false;
      if(this.companyUserCreated) {
        this.clearAddCompanyUserForm();
        this.toggleAddUserForm();
      }

      if (this.createCompanyUserError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to add user - Please check your inputs and try again'
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'User added successfully'
        });
      }
    },
    async addShareGroup() {
      this.creatingShareGroup = true;
      await this.$store.dispatch(CREATE_COMPANY_SHARE_GROUP, this.addCompanyShareGroupPayload);
      this.creatingShareGroup = false;
      if(this.companyShareGroupCreated) {
        this.clearAddCompanyShareGroupForm();
        this.toggleAddShareGroupForm();
      }

      if (this.createCompanyShareGroupError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to create the share group - Please try again'
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'Share group added successfully'
        });
      }
    },
    openDeleteUserModal(companyUser) {
      this.showDeleteUserModal = true;
      this.companyUserToDelete = companyUser;
    },
    closeDeleteUserModal() {
      this.showDeleteUserModal = false;
    },
    async deleteCompanyUser() {
      this.deletingUserId = this.companyUserToDelete.id;
      this.closeDeleteUserModal();
      await this.$store.dispatch(DELETE_COMPANY_USER, this.companyUserToDelete.id);
      this.deletingUserId = null;

      if (this.deleteCompanyUserError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to delete user - Please try again'
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'User deleted successfully'
        });
      }
    },
    openDeleteShareGroupModal(companyShareGroup) {
      this.showDeleteShareGroupModal = true;
      this.companyShareGroupToDelete = companyShareGroup;
    },
    closeDeleteShareGroupModal() {
      this.showDeleteShareGroupModal = false;
    },
    async deleteCompanyShareGroup() {
      this.deletingShareGroupId = this.companyShareGroupToDelete.id;
      this.closeDeleteShareGroupModal();
      await this.$store.dispatch(DELETE_COMPANY_SHARE_GROUP, this.companyShareGroupToDelete.id);
      this.deletingShareGroupId = null;

      if (this.deleteCompanyShareGroupError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to delete the share group - Please try again'
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'Share group deleted successfully'
        });
      }
    },
    toBasicRoleName(roleName) {
      return roleName.replace('role: ', '');
    },
    closeViewerModal() {
      this.showViewerModal=false;
      let updatingUser = this.companyUsers.find(x => x.id == this.updatingUserId);
      updatingUser.role = this.previousUserRole;
      updatingUser.subscription = this.previousUserSubscription;
      this.updatingUserId = null;
      this.updatingUserRole = null;
      this.previousUserRole = null;
      this.previousUserSubscription = null;
    },
    async changeViewerRole() {
      await this.$store.dispatch(UPDATE_COMPANY_USER, {
        companyUserId: this.updatingUserId,
        role: this.updatingUserRole,
        subscription: null});
      this.showViewerModal=false;
      this.updatingUserId = null;
      this.updatingUserRole = null;
      this.previousUserRole = null;
      this.previousUserSubscription = null;
      if(this.projectAdminId){
        await this.removeUserAsProjectAdmin(this.projectAdminId);
        this.projectAdminId = null;      
        //location.reload();  
      }
    },
    cancelViewerRoleChange() {
      this.projectAdminId = null; 
      let canceledEditUser = this.companyUsers.find(x => x.username == this.username);
      canceledEditUser.role = this.previousUserRole;
      this.updatingUserId = null;
    },
    async openViewerModal(){
      this.showViewerModal=true;
    },
    async updateCompanyUserRole(user, event) {
      this.updatingUserId = user.id;
      this.updatingUserRole = event;
      this.previousUserRole = user.role;
      this.previousUserSubscription = this.isUserRoleViewer(user.role) ? null : user.subscription;
      user.role = event;
      let delayAPICall = false;
      if(this.isUserRoleViewer(this.updatingUserRole)){
        const projectList = await this.setUserAdminProjectsNames(user.id);
        this.username = user.username;
        this.projectAdminId =  user.id;
      
        if(projectList.length > 0){
          delayAPICall = true;
          this.userProjectList = projectList;
          this.confirmRoleModalStatus += 1;
        }
      }

      if (!delayAPICall) {
        await this.$store.dispatch(UPDATE_COMPANY_USER, {
          companyUserId: user.id,
          role: event
        });
        this.updatingUserId = null;
        this.updatingUserRole = null;
        this.previousUserRole = null;
        this.previousUserSubscription = null;
        this.projectAdminId = null;
      }

    },
    async updateCompanyUserSubscription(userId, subscription) {
      this.updatingUserId = userId;
      await this.$store.dispatch(UPDATE_COMPANY_USER, {
        companyUserId: userId,
        subscription
      });
      this.updatingUserId = null;
    },
    openUpdateShareGroupModal(companyShareGroup) {
      this.setCompanyShareGroupToDefault = false;
      this.showUpdateShareGroupModal = true;
      this.companyShareGroupToUpdate = companyShareGroup;
    },
    closeUpdateShareGroupModal() {
      this.showUpdateShareGroupModal = false;
    },
    async updateCompanyShareGroup(shareGroupId) {
      this.updatingShareGroupId = shareGroupId;
      await this.$store.dispatch(UPDATE_COMPANY_SHARE_GROUP, {
        companyShareGroupId: shareGroupId,
        default_for_new_projects: true
      });
      this.updatingShareGroupId = null;
      this.showUpdateShareGroupModal = false;

      if (this.updateCompanyShareGroupError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to update the share group - Please try again'
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'Users successfully added to the share group'
        });
      }
    },
    async getCompanyShareGroupUsers(companyShareGroupId) {
      this.companyShareGroupUsersLoading = true;
      await this.$store.dispatch(GET_COMPANY_SHARE_GROUP_USERS, companyShareGroupId);
      this.companyShareGroupUsersLoading = false;
    },
    async goToCompanyShareGroupUsers(companyShareGroup) {
      this.showAddShareGroupForm = false;
      this.selectedCompanyShareGroup = companyShareGroup;
      this.showCompanyShareGroupUsers = true;
      await this.getCompanyShareGroupUsers(companyShareGroup.id);
    },
    goToCompanyShareGroups() {
      this.showCompanyShareGroupUsers = false;
    },
    async removeCompanyShareGroupUser(companyShareGroupUserId) {
      this.deletingShareGroupUserId = companyShareGroupUserId;
      await this.$store.dispatch(REMOVE_COMPANY_SHARE_GROUP_USER, {
        companyShareGroupId: this.selectedCompanyShareGroup.id,
        companyShareGroupUserId
      });
      this.deletingShareGroupUserId = null;

      if (this.createCompanyShareGroupError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to remove user from the share group - Please try again'
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'User successfully removed from the share group'
        });
      }
    },
    openAddCompanyUsersToShareGroupModal() {
      this.companyUsersToAddToShareGroup.forEach(user => delete user.selected);
      this.selectedCompanyUsersToAddToShareGroup = [];
      this.showAddCompanyUsersToShareGroupModal = true;
    },
    closeAddCompanyUsersToShareGroupModal() {
      this.showAddCompanyUsersToShareGroupModal = false;
    },
    updateSelectedCompanyUsersToAddToShareGroup(companyUser) {
      let companyUserInSelection = this.selectedCompanyUsersToAddToShareGroup.some(user => user.id === companyUser.id);
      if(companyUserInSelection) {
        companyUser.selected = false;
        this.selectedCompanyUsersToAddToShareGroup = this.selectedCompanyUsersToAddToShareGroup.filter(user => user.id !== companyUser.id);
      } else {
        companyUser.selected = true;
        this.selectedCompanyUsersToAddToShareGroup.push(companyUser);
      }
    },
    async addSelectedCompanyUsersToShareGroup() {
      this.addingShareGroupUsers = true;
      await this.$store.dispatch(ADD_COMPANY_SHARE_GROUP_USERS, {
        companyShareGroupId: this.selectedCompanyShareGroup.id,
        companyShareGroupUsers: this.selectedCompanyUsersToAddToShareGroup
      });
      this.addingShareGroupUsers = false;
      this.closeAddCompanyUsersToShareGroupModal();
    },
    async companySubscriptionUsageSortChanged(ctx) {
      this.subscriptionUsagePage = 1;
      this.companySubscriptionUsageSortBy = ctx.sortBy;
      this.companySubscriptionUsageSortDesc = ctx.sortDesc;
      await this.subscriptionUsagePageChange(this.subscriptionUsagePage);
    },
    async subscriptionUsagePageChange(page) {
      if (!page) {
        return;
      }
      this.companySubscriptionUsageLoading = true;
      await this.$store.dispatch(GET_COMPANY_SUBSCRIPTION_USAGE,
        { pageNum: page,
          orderBy: orderByKey(this.companySubscriptionUsageSortBy, this.companySubscriptionUsageSortDesc)});
      this.companySubscriptionUsageLoading = false;
    }
  },
  watch: {
    async companyUsersPage(newValue) {
      await this.$store.dispatch(GET_COMPANY_USERS, newValue);
    },
    async addCompanyUsersPage(newValue) {
      await this.$store.dispatch(GET_COMPANY_USERS_TO_ADD, newValue);
    }
  }
};
</script>

<style>
  thead.admin-tables-header {
    background-color: #E9ECEF;
  }
</style>